<template>
  <teleport to="body">
    <div v-if="isOpen" ref="file__preview" class="file__preview">
      <div class="file__preview-inner">
        <div class="file__preview-header">
          <div ref="topLine" class="file__preview-header-inner">
            <div class="file__preview-header-left">
              <img :src="filePreview.iconUrl" alt="attachment"> {{filePreview.fileName}}
            </div>
            <div class="file__preview-header-center">
              <button class="zoom__out" @click="handleZoomOut">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M2 12a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1" clip-rule="evenodd"></path></svg>
              </button>
              <div class="zoom__value">{{ renderZoomValue }}%</div>
              <button class="zoom__in" @click="handleZoomIn">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M12 2a1 1 0 0 1 1 1v7.69h8a1 1 0 1 1 0 2h-8V21a1 1 0 1 1-2 0v-8.31H3a1 1 0 0 1 0-2h8V3a1 1 0 0 1 1-1" clip-rule="evenodd"></path></svg>
              </button>
            </div>
            <div class="file__preview-header-right">
              <a :href="filePreview.downloadLink" class="download">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M12.9 3.5a.9.9 0 1 0-1.8 0v10.827l-2.464-2.463a.9.9 0 1 0-1.272 1.272l4 4a.9.9 0 0 0 1.272 0l4-4a.9.9 0 1 0-1.272-1.272L12.9 14.327zM2 16.6a.9.9 0 0 1 .9.9V19c0 .335.268.6.595.6h17.008a.597.597 0 0 0 .597-.6v-1.5a.9.9 0 1 1 1.8 0V19c0 1.323-1.068 2.4-2.396 2.4H3.495A2.396 2.396 0 0 1 1.1 19v-1.5a.9.9 0 0 1 .9-.9" clip-rule="evenodd"></path></svg>
                {{ t('Download') }}
              </a>
              <button class="close" @click="closeModal">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.6973 8.00025L14.8255 2.87209L15.883 1.81456C16.039 1.65855 16.039 1.40504 15.883 1.24904L14.7515 0.117504C14.5955 -0.0385011 14.3419 -0.0385011 14.1859 0.117504L8.00025 6.3032L1.81456 0.117004C1.65855 -0.0390012 1.40504 -0.0390012 1.24904 0.117004L0.117004 1.24854C-0.0390012 1.40454 -0.0390012 1.65805 0.117004 1.81406L6.3032 8.00025L0.117004 14.1859C-0.0390012 14.3419 -0.0390012 14.5955 0.117004 14.7515L1.24854 15.883C1.40454 16.039 1.65805 16.039 1.81406 15.883L8.00025 9.6973L13.1284 14.8255L14.1859 15.883C14.3419 16.039 14.5955 16.039 14.7515 15.883L15.883 14.7515C16.039 14.5955 16.039 14.3419 15.883 14.1859L9.6973 8.00025Z" /></svg>
                <span class="tooltip__default">{{ t('Close') }}</span>
              </button>
            </div>
          </div>
        </div>
        <main class="main__content">
          <div v-show="isRendering" class="file__preview-loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <div v-show="!isRendering" ref="pdfContainer" class="pdf-container"></div>
          <div v-if="showError" class="file__preview-error">
            {{ t('Preview is not available') }}
          </div>
        </main>
      </div>
    </div>
  </teleport>
</template>

<script>
import { useI18n } from "vue-i18n";
import * as pdfjsLib from 'pdfjs-dist/webpack';

export default {
  props: {
    pdfUrl: String,
    isOpen: Boolean,
    filePreview: Object
  },
  emits: ['close'],
  data() {
    return {
      scale: 1,
      zoomValue: 100,
      isRendering: true,
      showError: false,
    }
  },
  setup(){
    const { t } = useI18n();
    return { t };
  },
  computed: {
    renderZoomValue() {
      return this.zoomValue * this.scale;
    }
  },
  created() {
    document.addEventListener('keydown', this.escapePressed);
  },
  unmounted() {
    document.removeEventListener('keydown', this.escapePressed);
  },
  mounted() {
    this.renderPdf();
  },
  methods: {
    handleZoomIn() {
      if (this.scale === 2) return;

      this.scale += 0.25;
      this.changeScale(this.scale)
    },
    handleZoomOut() {
      if (this.scale === 0.25) return;

      this.scale -= 0.25;
      this.changeScale(this.scale)
    },
    closeModal() {
      this.$emit("close");
    },
    escapePressed(logKey) {
      if(logKey.code === 'Escape') {
        this.$emit('close')
      }
    },
    changeScale(newScale) {
      if (newScale >= 0.25 && newScale <= 2) {
        this.scale = newScale;
        this.renderAllPages();
      }
    },
    async renderPdf() {
      try {
        this.pdfDoc = await pdfjsLib.getDocument(this.pdfUrl).promise;
        this.renderAllPages();
      } catch (error) {
        console.error('Could not load PDF: ', error);
        this.isRendering = false;
        this.showError = true;
      }
    },
    async renderAllPages() {
      const container = this.$refs.pdfContainer;
      container.innerHTML = '';

      const pagePromises = [];
      for (let pageNumber = 1; pageNumber <= this.pdfDoc.numPages; pageNumber++) {
        pagePromises.push(this.pdfDoc.getPage(pageNumber).then(page => this.createPageContainer(page)));
      }

      try {
        const pages = await Promise.all(pagePromises);
        const fragment = document.createDocumentFragment();
        pages.forEach(pageContainer => fragment.appendChild(pageContainer));
        container.appendChild(fragment);
      } catch (error) {
        console.error('Error rendering pages:', error);
        this.isRendering = false;
        this.showError = true;
      }
    },
    async createPageContainer(page) {
      const deviceScale = window.devicePixelRatio || 1;
      const scale = this.scale * deviceScale;
      const viewport = page.getViewport({ scale });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d', { willReadFrequently: true });

      canvas.width = viewport.width;
      canvas.height = viewport.height;
      canvas.style.width = `${viewport.width / deviceScale}px`;
      canvas.style.height = `${viewport.height / deviceScale}px`;

      context.imageSmoothingEnabled = false;
      context.imageSmoothingQuality = 'high';

      const pageContainer = document.createElement('div');
      pageContainer.classList.add('pdf-page');
      pageContainer.style.margin = '0 auto';

      await page.render({ canvasContext: context, viewport }).promise;
      this.isRendering = false;
      pageContainer.appendChild(canvas);

      return pageContainer;
    },
  }
};
</script>

<style scoped lang="scss">
  .file__preview{
    z-index: 9999999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    height: 100vh;
    background-color: #323234;

    &-inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
    &-error {
      color: #f5f5f5;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    &-loader,
    &-loader div,
    &-loader div:after {
      box-sizing: border-box;
    }
    &-loader {
      display: inline-block;
      width: 80px;
      height: 80px;
      position: absolute;
      top: calc(50% - 40px);
      left: calc(50% - 40px);
    }
    &-loader div {
      animation: file__preview-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
      color: #c8c8c8;
    }
    &-loader div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7.2px;
      height: 7.2px;
      border-radius: 50%;
      background: currentColor;
      margin: -3.6px 0 0 -3.6px;
    }
    &-loader div:nth-child(1) {
      animation-delay: -0.036s;
    }
    &-loader div:nth-child(1):after {
      top: 62.62742px;
      left: 62.62742px;
    }
    &-loader div:nth-child(2) {
      animation-delay: -0.072s;
    }
    &-loader div:nth-child(2):after {
      top: 67.71281px;
      left: 56px;
    }
    &-loader div:nth-child(3) {
      animation-delay: -0.108s;
    }
    &-loader div:nth-child(3):after {
      top: 70.90963px;
      left: 48.28221px;
    }
    &-loader div:nth-child(4) {
      animation-delay: -0.144s;
    }
    &-loader div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }
    &-loader div:nth-child(5) {
      animation-delay: -0.18s;
    }
    &-loader div:nth-child(5):after {
      top: 70.90963px;
      left: 31.71779px;
    }
    &-loader div:nth-child(6) {
      animation-delay: -0.216s;
    }
    &-loader div:nth-child(6):after {
      top: 67.71281px;
      left: 24px;
    }
    &-loader div:nth-child(7) {
      animation-delay: -0.252s;
    }
    &-loader div:nth-child(7):after {
      top: 62.62742px;
      left: 17.37258px;
    }
    &-loader div:nth-child(8) {
      animation-delay: -0.288s;
    }
    &-loader div:nth-child(8):after {
      top: 56px;
      left: 12.28719px;
    }
  }

  @keyframes file__preview-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .file__preview-header{
    text-align: center;
    flex: 0 0 auto;
    width: 100%;
    overflow: auto hidden;
    z-index: 10;
    height: 53px;
    background-color: #323234;
    border-bottom: 1px solid #4b4b4e;

    @media (min-width: 767px){
      overflow: initial;
    }
  }

  .file__preview-header-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;
    gap: 8px;
  }

  button{
    background: none;
    color: inherit;
    padding: 10px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    transition: all .2s ease-in-out;
    border: 1px solid transparent;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }

  .close,
  .zoom__out,
  .zoom__in {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;

    svg{
      fill: #fafafa;
      transition: all .2s ease-in-out;
      flex-shrink: 0;
    }

    &:hover{
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.2);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      border-radius: 3px;

      .tooltip__default {
        opacity: 1;
      }

      svg{
        fill: #fff !important;
      }
    }
  }

  .zoom__value {
    color: #fff;
    font-weight: 600;
    margin: 0 6px;
  }

  .tooltip__default{
    opacity: 0;
    color: #fff;
    text-align: center;
    padding: 10px 16px;
    position: absolute;
    z-index: 1;
    top: calc(100% + 6px);
    background: #494949;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    transition: all .2s ease-in-out;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    cursor: pointer;
    pointer-events: none;

    &::after {
      content: " ";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #494949 transparent;
    }

    &.tooltip__default-left{
      right: -1px;

      &::after {
        left: initial;
        right: 13px;
      }
    }
  }

  .file__preview-header-left{
    font-size: 14px;
    line-height: 18px;
    color: #FFF;
    display: flex;
    align-items: center;
    white-space: nowrap;

    img{
      width: 17px;
      margin-right: 17px;
    }
  }

  .file__preview-header-center {
    text-align: center;
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    @media (max-width: 767px){
      position: static;
      transform: none;
    }
  }

  .file__preview-header-right{
    font-size: 14px;
    line-height: 18px;
    color: #FFF;
    display: flex;
    align-items: center;
    margin: 0 20px;
    text-align: left;

    img{
      width: 17px;
      margin-right: 17px;
    }

    .download {
      text-decoration: none;
      color: #fff;
      padding: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      svg {
        margin-right: 8px;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.2);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border-radius: 3px;

        .tooltip__default {
          opacity: 1;
        }

        svg {
          fill: #fff !important;
        }
      }
    }
  }

  .main__content{
    overflow-y: auto;
    max-width: 100%;
    height: 100%;
    position: relative;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: #1f1f1f;
    }

    &::-webkit-scrollbar {
      background-color: hsl(0, 0%, 98%);
      width: 14px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #686868;
      border: 3px solid transparent;
      background-clip: content-box;

      &:hover {
        background-color: #7b7b7b;
      }
    }

    .file__item{
      margin-bottom: 20px;
    }

    .pdf-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }
</style>
