export function addTargetAttributeToLinks(text) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');

    const links = doc.querySelectorAll('a[href]')

    links.forEach(link => {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener nofollow noreferrer');
    });

    return new XMLSerializer().serializeToString(doc);
}

export function getFileIcon(extension) {
    const url = '../assets/file-icons/';
    let ext;

    switch (extension) {
        case 'docx':
        case 'doc':
        case 'pages':
            ext = 'DOC.svg';
            break;
        case 'json':
        case 'xml':
            ext = 'Link.svg';
            break;
        case 'jpg':
        case 'png':
        case 'gif':
        case 'jpeg':
        case 'tif':
        case 'tiff':
        case 'bpm':
        case 'ppm':
        case 'pgm':
        case 'pbm':
        case 'pnm':
        case 'jfif':
        case 'exif':
        case 'svg':
            ext = 'Image.svg';
            break;
        case 'avi':
        case 'flv':
        case 'wmv':
        case 'mov':
        case 'mp4':
        case 'mkv':
        case 'mpeg':
        case 'mpg':
        case '3gp':
            ext = 'Video.svg';
            break;
        case 'pdf':
            ext = 'PDF.svg';
            break;
        case '7z':
        case 'cab':
        case 'gzip':
        case 'tar':
        case 'tgz':
        case 'zip':
        case 'rar':
            ext = 'Archive.svg';
            break;
        case 'html':
        case 'htm':
            ext = 'HTML.svg';
            break;
        case 'ac3':
        case 'mp3':
        case 'midi':
        case 'wav':
        case 'wave':
            ext = 'Audio.svg';
            break;
        case 'pptx':
        case 'ppt':
        case 'pptm':
        case 'potx':
        case 'pot':
        case 'ppsx':
        case 'ppsm':
        case 'pps':
            ext = 'Presentation.svg';
            break;
        case 'xls':
        case 'xlsx':
        case 'xlsm':
        case 'numbers':
        case 'csv':
            ext = 'Spreadsheet.svg';
            break;
        case 'txt':
        case 'odt':
        case 'rtf':
        case 'tex':
        case 'wks':
        case 'wps':
        case 'wpd':
        case 'psd':
            ext = 'Document.svg';
            break;
        default:
            ext = 'Other.svg';
    }

    return url + ext;
}

export function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}
