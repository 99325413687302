<template>
  <teleport to="body">
    <div ref="file__preview" class="file__preview">
      <div class="file__preview-inner">
        <div class="file__preview-header">
          <div ref="topLine" class="file__preview-header-inner">
          <div class="file__preview-header-left">
            <img :src="filePreview.iconUrl" alt="attachment"> {{filePreview.fileName}}
          </div>
          <div class="file__preview-header-right">
            <a :href="filePreview.downloadLink" class="download">
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M12.9 3.5a.9.9 0 1 0-1.8 0v10.827l-2.464-2.463a.9.9 0 1 0-1.272 1.272l4 4a.9.9 0 0 0 1.272 0l4-4a.9.9 0 1 0-1.272-1.272L12.9 14.327zM2 16.6a.9.9 0 0 1 .9.9V19c0 .335.268.6.595.6h17.008a.597.597 0 0 0 .597-.6v-1.5a.9.9 0 1 1 1.8 0V19c0 1.323-1.068 2.4-2.396 2.4H3.495A2.396 2.396 0 0 1 1.1 19v-1.5a.9.9 0 0 1 .9-.9" clip-rule="evenodd"></path></svg>
              {{ t('Download') }}
            </a>
            <button class="close" @click="close">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.6973 8.00025L14.8255 2.87209L15.883 1.81456C16.039 1.65855 16.039 1.40504 15.883 1.24904L14.7515 0.117504C14.5955 -0.0385011 14.3419 -0.0385011 14.1859 0.117504L8.00025 6.3032L1.81456 0.117004C1.65855 -0.0390012 1.40504 -0.0390012 1.24904 0.117004L0.117004 1.24854C-0.0390012 1.40454 -0.0390012 1.65805 0.117004 1.81406L6.3032 8.00025L0.117004 14.1859C-0.0390012 14.3419 -0.0390012 14.5955 0.117004 14.7515L1.24854 15.883C1.40454 16.039 1.65805 16.039 1.81406 15.883L8.00025 9.6973L13.1284 14.8255L14.1859 15.883C14.3419 16.039 14.5955 16.039 14.7515 15.883L15.883 14.7515C16.039 14.5955 16.039 14.3419 15.883 14.1859L9.6973 8.00025Z" /></svg>
              <span class="tooltip__default">{{ t('Close') }}</span>
            </button>
          </div>
        </div>
        </div>
        <main class="main__content">
          <div v-for="(image, index) in base64Images" :key="index" class="image__container">
            <img :src="image" alt="image" class="preview__image">
          </div>
        </main>
      </div>
    </div>
  </teleport>
</template>

<script>
  import { useI18n } from "vue-i18n";

  export default {
    setup(){
      const { t } = useI18n();
      return { t };
    },
    emits: ['close', 'arrow-click'],
    props: ['attachment', 'filePreview'],
    data() {
      return{
        currentImageIndex: 0,
        base64Images: this.attachment.files.map(file => `data:application/${this.attachment.ext};base64,${file.base64}`),
      }
    },
    created() {
      document.addEventListener('keydown', this.escapePressed);
    },
    unmounted() {
      document.removeEventListener('keydown', this.escapePressed);
    },
    methods: {
      close() {
        this.$emit('close')
      },
      escapePressed(logKey) {
        if(logKey.code === 'Escape') {
          this.$emit('close')
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .file__preview{
    z-index: 9999999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    height: 100vh;
    background-color: #323234;

    &-inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
  }

  .file__preview-header{
    text-align: center;
    flex: 0 0 auto;
    width: 100%;
    overflow: auto hidden;
    z-index: 10;
    height: 53px;
    background-color: #323234;
    border-bottom: 1px solid #4b4b4e;

    @media (min-width: 767px){
      overflow: initial;
    }
  }

  .file__preview-header-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;
    gap: 8px;
  }

  button{
    background: none;
    color: inherit;
    padding: 10px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    transition: all .2s ease-in-out;
    border: 1px solid transparent;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }

  .file__preview-header-left{
    font-size: 14px;
    line-height: 18px;
    color: #FFF;
    display: flex;
    align-items: center;
    white-space: nowrap;

    img{
      width: 17px;
      margin-right: 17px;
    }
  }

  .file__preview-header-center {
    text-align: center;
    display: flex;
    align-items: center;
  }

  .file__preview-header-right{
    font-size: 14px;
    line-height: 18px;
    color: #FFF;
    display: flex;
    align-items: center;
    margin: 0 20px;
    text-align: left;

    img{
      width: 17px;
      margin-right: 17px;
    }
  }

  .close {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;

    svg{
      fill: #fafafa;
      transition: all .2s ease-in-out;
      flex-shrink: 0;
    }

    &:hover{
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.2);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      border-radius: 3px;

      .tooltip__default {
        opacity: 1;
      }

      svg{
        fill: #fff !important;
      }
    }
  }

  .download {
    text-decoration: none;
    color: #fff;
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;

    svg {
      margin-right: 8px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      border-radius: 3px;

      .tooltip__default {
        opacity: 1;
      }

      svg {
        fill: #fff !important;
      }
    }
  }

  .tooltip__default{
    opacity: 0;
    color: #fff;
    text-align: center;
    padding: 10px 16px;
    position: absolute;
    z-index: 1;
    top: calc(100% + 6px);
    background: #494949;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    transition: all .2s ease-in-out;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    cursor: pointer;
    pointer-events: none;

    &::after {
      content: " ";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #494949 transparent;
    }

    &.tooltip__default-left{
      right: -1px;

      &::after {
        left: initial;
        right: 13px;
      }
    }
  }

  .main__content{
    overflow-y: auto;
    max-width: 100%;
    height: 100%;
    position: relative;
    outline: none;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: #1f1f1f;
    }

    &::-webkit-scrollbar {
      background-color: hsl(0, 0%, 98%);
      width: 14px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #686868;
      border: 3px solid transparent;
      background-clip: content-box;

      &:hover {
        background-color: #7b7b7b;
      }
    }

    &::-webkit-scrollbar-corner {
      background-color: hsl(0, 0%, 98%);
    }

    &::-webkit-scrollbar-button {
      background-color: #343030;
    }

    .file__item{
      margin-bottom: 20px;
    }

    .pdf-container {
      height: 100%;
    }

    img {
      max-width: 82%;

      @media (min-width: 1000px){
        max-width: 60%;
      }
    }
  }
</style>
